import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    customRender: (value, item, index) => index + 1,
    fixed: "left",
    width: 60,
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    width: 150,
    fixed: "left",
    ellipsis: true,
  },
  {
    title: T("收货数量"),
    dataIndex: "receipt_quantity",
    width: 120,
    scopedSlots: { customRender: "receipt_quantity" },
  },
  {
    title: T("计费量"),
    dataIndex: "charge_value",
    key: "charge_value",
    width: 120,
    scopedSlots: { customRender: "charge_value" },
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
    width: 180,
    scopedSlots: { customRender: "batch_number" },
  },
  {
    title: T("质检状态"),
    dataIndex: "status",
    key: "quality_inspection_status",
    width: 120,
    scopedSlots: { customRender: "status" },
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
    width: 180,
  },
  {
    title: T("待收货数量"),
    dataIndex: "remain_quantity",
    width: 120,
  },

  {
    title: T("生产日期"),
    dataIndex: "production_date",
    width: 120,
    scopedSlots: { customRender: "production_date" },
  },
  {
    title: T("单位"),
    dataIndex: "material_unit",
    width: 80,
  },
  {
    title: T("计费单位"),
    width: 120,
    dataIndex: "charge_unit_display",
    key: "charge_unit",
  },
  {
    title: T("规格"),
    dataIndex: "material_spec",
    width: 120,
  },
  {
    title: T("启用保质期"),
    dataIndex: "enable_shelf_life",
    width: 160,
    customRender: (value, item, index) => {
      return value ? T("启用") : T("关闭");
    },
  },
  {
    title: T("保质期天数"),
    dataIndex: "shelf_life_days",
    width: 120,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 80,
    fixed: "right",
  },
];
